import { useMemo } from 'react';
import { Size } from '../types';
import { useWindowSize } from './useWindowSize';

function useResponsive<T>(getValue: (size: Size | undefined) => T) {
    const size = useWindowSize();

    const image = useMemo<T>(() => getValue(size), [size]);

    return image;
}

export { useResponsive };
