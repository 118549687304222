import React from 'react';
import styled from '@emotion/styled';
import { useResponsive } from '../services/useResponsive';
import { CloudinaryOptions } from '../services/cloudinary';
import { CloudinaryImage } from './CloudinaryImage';
import { ImageWithSize, Size } from '../types';

interface ResponsiveCloudinaryImageProps {
    image: ImageWithSize;
    getOptions: (size: Size | undefined) => CloudinaryOptions | undefined;
    className?: string;
}

function ResponsiveCloudinaryImage(props: ResponsiveCloudinaryImageProps) {
    const { image, getOptions, className } = props;

    const options = useResponsive(getOptions);

    return (
        <CloudinaryImage
            image={image}
            options={options}
            className={className}
        />
    );
}

export { ResponsiveCloudinaryImage };
