import React from 'react';
import styled from '@emotion/styled';
import { ImageWithSize } from '../types';

const Root = styled.div`
    height: ${(props: ImageWithSize) => props.height}px;
    width: ${(props: ImageWithSize) => props.width}px;
    background-image: url(${(props: ImageWithSize) => props.url});
    background-repeat: no-repeat;
    background-size: cover;
`;

interface ImageProps {
    image: ImageWithSize;
    className?: string;
}

function Image(props: ImageProps) {
    const { image, className } = props;

    return <Root {...image} className={className} />;
}

export { Image };
