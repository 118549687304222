import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore: No declarations...
import { shallowEqualObjects } from 'shallow-equal';
import {
    CloudinaryOptions,
    transformCloudinaryImage,
} from '../services/cloudinary';
import { ImageWithSize } from '../types';
import { Image } from './Image';

interface CloudinaryImageProps {
    image: ImageWithSize;
    options?: CloudinaryOptions;
    className?: string;
}

function CloudinaryImage(props: CloudinaryImageProps) {
    const { image, options, className } = props;

    const [actualOptions, setActualOption] = useState(options);

    const actualImage = useMemo(
        () => transformCloudinaryImage(image, actualOptions),
        [image, actualOptions],
    );

    useEffect(() => {
        if (options && !shallowEqualObjects(actualOptions, options)) {
            setActualOption(options);
        }
    }, [actualOptions, options]);

    if (!actualImage) {
        return null;
    }

    return <Image image={actualImage} className={className} />;
}

export { CloudinaryImage };
